.commentCanDelete {
  &:hover {
    .commentDate {
      display: none;
    }
    .deleteCommentIcon {
      display: block;
    }
  }
}

.commentHeader {
  display: flex;
}

.commentNamePlateContainer {
  background-color: #9ea0a5;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.commentNamePlateDeactive {
  background: none;
  border: 1px solid #bac0cb;

  .commentNamePlate {
    color: #bac0cb;
  }
}

.commentNamePlate {
  color: #fff;
  font-size: 12px; /* 50% of parent */
  font-weight: 500;
  line-height: 1;
  position: relative;
  top: calc(28px / 12); /* 25% of parent */
}

.commentName {
  height: 15px;
  color: #eaedf3;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

.commentHeaderRight {
  margin-left: auto;
}

.commentDate {
  height: 18px;
  color: #9ea0a5;

  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  text-align: right;
}
.commentDelete {
}

.commentFromOthers {
  background-color: transparent;
  border-radius: 10px 0 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.38);
  color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0 30px 0;
  padding: 20px;
}

.commentFromMe {
  background-color: #0a7cb8;
  border-radius: 0 10px 10px 10px;
  border: 1px solid #0a7cb8;
  color: #fff;
  cursor: default;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 12px 0 30px 0;
  padding: 20px;
}

.deleteCommentIcon {
  float: right;
  display: none;
  cursor: pointer;
}

.timestamp {
  color: #84e2eb;
  display: block;
  font-size: 0.75rem;
  cursor: pointer;
}

.nameContainer {
  display: flex;
  align-items: center;
}

.myCommentHighlight {
  background-color: #215d84;
}

.othersCommentHighlight {
  background-color: #212536;
}

.highlight {
  background-color: #fff104;
  color: black;
}
