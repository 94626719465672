@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,900");
html,
body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  width: 100%;
  background-color: #fbfbfd;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.clear {
  clear: both;
}

/* Custom Dashboard UI Kit styles */
.uik-btn__base.uik-btn__primary {
  background: linear-gradient(0deg, #1b8c96 0%, #1b8c96 100%) !important;
  border: 1px solid #166f77 !important;
}
.uik-input__errorMessage {
  font-size: 12px;
}

input,
textarea,
select,
button {
  font-family: "Montserrat", sans-serif !important;
}

a:link {
  text-decoration: none !important;
}
a:visited {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
a:active {
  text-decoration: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

ul {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.react-datepicker-wrapper {
}

.uik-content-title__wrapper {
  font-weight: 600 !important;
}

.main-headline {
  font-weight: 500;
}

/* For document commenting/referencing */
.selectedDocumentText {
  padding: 3px;
  margin: -3px 0 0 -3px;
  background-color: rgba(0, 138, 185, 0.5);
}

.commentedText {
  padding: 3px;
  margin: -3px 0 0 -3px;
  background-color: rgba(255, 156, 121, 0.5);
}

.referencedText {
  padding: 3px;
  margin: -3px 0 0 -3px;
  background-color: rgba(122, 106, 141, 0.5);
}

/* Animation - Slide element from left */
.slideInLeft-enter {
  left: 450px;
}

.slideInLeft-enter.slideInLeft-enter-active {
  left: 0px;
  -webkit-transition: left 0.4s ease;
}

.slideInLeft-leave {
  left: 0px;
}

.slideInLeft-leave.slideInLeft-leave-active {
  left: 450px;
  -webkit-transition: left 0.4s ease;
}

/* Animation - Fade element in */
.fadeIn-enter {
  opacity: 0;
}

.fadeIn-enter.fadeIn-enter-active {
  opacity: 1;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.fadeIn-leave {
  opacity: 1;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.fadeIn-leave.fadeIn-leave-active {
  opacity: 0;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.react-notification-root .notification-item-root {
  width: 320px;
}

.react-datepicker__close-icon {
  height: 22px !important;
  /*margin-top: 0px !important;*/
}

.customDatePickerWidth,
.customDatePickerWidth .react-datepicker__input-container,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100% !important;
}
