.commentPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  overflow-y: auto;
}

.mobileCommentPanel {
  background-color: #393d50;
  color: #fff;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  overflow-y: auto;
}

.comment {
  padding: 0 20px;
  word-break: break-word;
  white-space: pre-wrap;
  cursor: pointer;
}

.messageInput {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  opacity: 1;
  background: rgba(44, 48, 65, 1);
  width: 100%;
  overflow-y: auto;
  z-index: 99;
  min-height: 150px;
}

.messageInput::-webkit-scrollbar-track {
  background-color: rgb(50, 53, 68);
}

.messageInput::-webkit-scrollbar {
  width: 8px;
}

.messageInput::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}

.messageInput {
  scrollbar-color: #868e96 #2e313f;
  scrollbar-width: thin;
}

.messageInputField,
.messageInputField:focus {
  background-color: #393d50;
  border: none;
  color: #fff;
  font-size: 14px;
  border-width: 0px;
  outline: none !important;
  padding: 20px 20px;
  resize: none;
  width: 80%;
}

.messageInputSubmit {
  display: flex;
  padding: 20px 15px 20px 15px;
  justify-content: space-between;
  border-top: 1px solid rgba(234, 237, 243, 0.2);
  background: #2c3041;
  .sendButton {
    cursor: pointer;
    background: none;
    border: none;
    width: 36px;
    height: 34px;
    overflow: hidden;
    padding: 0;
    color: #9d9fa4;
    font-size: 20px;

    .hideSendButton {
      display: none;
    }

    .loading {
      width: 16px;
      height: 16px;
      animation: spin 1s infinite;
    }

    &:focus {
      outline: none;
    }
  }
}

.messageInputSubmitGuest {
  display: flex;
  padding: 20px 15px 20px 15px;
  justify-content: flex-end;
  border-top: 1px solid rgba(234, 237, 243, 0.2);
  background: #2c3041;
  .sendButton {
    cursor: pointer;
    background: none;
    border: none;
    width: 36px;
    height: 34px;
    overflow: hidden;
    padding: 0;
    color: #9d9fa4;
    font-size: 20px;

    .hideSendButton {
      display: none;
    }

    .loading {
      width: 16px;
      height: 16px;
      animation: spin 1s infinite;
    }

    &:focus {
      outline: none;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sidePanelOptions {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding: 20px 0px 0px;
  overflow-y: auto;
  height: calc(100% - 245px);
}

.sidePanelOptions::-webkit-scrollbar-track {
  background-color: rgb(50, 53, 68);
}

.sidePanelOptions::-webkit-scrollbar {
  width: 8px;
}

.sidePanelOptions::-webkit-scrollbar-thumb {
  background-color: rgb(146, 157, 170);
  border-radius: 8px;
}

.sidePanelOptions {
  scrollbar-color: #868e96 #2e313f;
  scrollbar-width: thin;
}

.sidePanelHeader {
  border-bottom: 1px solid rgba(234, 237, 243, 0.2);
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 0;
  padding: 20px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  opacity: 1;
  background: #393d50;
  z-index: 99;

  .checkmark {
    float: right;
    margin: 2px 25px 0 0;
  }
}

.conversationIconContainer {
  background-color: #ff9c79;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  height: 28px;
  width: 28px;
}

.conversationIcon {
  font-size: calc(28px / 2); /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(28px / 7); /* 25% of parent */
}

.closeComment {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 26px;
  float: right;
  height: 16px;
  width: 16px;
}

.conversationActionsDropDownContainer {
  float: right;
  margin: 1px 25px 0 0;
  cursor: pointer;
}

.commentSendIcon {
  border: none;
}

.moreIcon {
  position: relative;
  top: 3px;
}

.addTimestamp {
  font-size: 0.75rem;
  margin: 5px 20px;
}

.editor {
  width: calc(100% - 40px);
  margin-left: 20px;
  padding: 20px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.editor :global(.public-DraftEditor-content) {
  overflow: scroll;
  max-height: 50vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.sidePanelHeadingTitle {
  font-size: 24px;
  font-weight: 500;
}

.backArrow {
  margin-right: 10px;
  cursor: pointer;
}

.showSendButton {
  background: #1b8c96;
  padding: 7px 9px 7px 9px;
  height: 20px;
  border-radius: 10%;
}

.atButton {
  cursor: pointer;
  background: none;
  border: none;
  width: 36px;
  height: 34px;
  overflow: hidden;
  padding: 0;
  color: #9d9fa4;
  font-size: 20px;
}

.atButton:hover {
  background: #393d50;
  border-radius: 10%;
  padding-bottom: 2.5px;
}

.foundClaimContainer {
  background: #2c3041;
  border-radius: 10px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 20px;
}

.foundClaimTop {
  display: flex;
  justify-content: space-between;
  margin: 15px 15px 0px 15px;
}

.foundClaimBottom {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.expandIcon {
  cursor: pointer;
}

.foundClaimTitleContainer {
  display: flex;
  width: 50%;
}

.dividerContainer {
  border-top: 1px solid #5b5f72;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

.foundClaimTitle {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: white;
  text-transform: uppercase;
  margin-left: 10px;
}

.helpText {
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(214, 220, 232, 1);
}

@media screen and (min-width: 1440px) {
  .commentPanel {
    background-color: #393d50;
    color: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    overflow-y: auto;
  }
}

@media (max-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .commentPanel {
    background-color: #393d50;
    color: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    overflow-y: auto;
  }
}
