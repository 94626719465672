.container {
  width: 320px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background: #393d50;
  padding: 15px 15px 0px 15px;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 15px;
    border-bottom: 1px solid #5b5f72;

    .text {
      font-weight: 500;
      font-size: 24px;
      color: white;
    }

    .closeIcon {
      display: flex;
      cursor: pointer;
    }
  }

  .body {
    height: 100%;
    overflow-y: scroll;
    .signedBy {
      color: white;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-top: 23px;
    }

    .signedAt {
      margin-top: 17px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      color: #eaedf3;
    }
  }
}

@media screen and (min-width: 1440px) {
  .container {
    width: 400px;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    background: #393d50;
    padding: 15px 15px 0px 15px;
    box-sizing: border-box;
  }
}

.inputContainer {
  padding: 0px 3% 19px 3%;
}

.formContainer {
  padding-right: 15px;
  padding-left: 15px;
}

.claimsForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  height: 100%;
}

.formButton {
  display: flex;
  justify-content: center;
  padding: 30px;
  border-top: 1px solid rgba(234, 237, 243, 0.2);
}

.searchInputField {
  margin: 0 auto;
  margin-top: 10px;
  background-color: #2c3041;
  border: none;
  color: #8a8e96;
}

.searchInputField:disabled {
  margin: 0 auto;
  margin-top: 10px;
  background-color: #2c3041;
  border: none;
  color: #8a8e96;
}

.inputHeader {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(158, 160, 165, 1);
}

.claimsFormContainer {
  height: 100%;
}

.labelPlaceHolder {
  width: 117px;
  height: 9px;

  background: #2c3041;
  border-radius: 2px;
}

.inputPlaceHolder {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 35px;

  background: #2c3041;
  border-radius: 2px;
}

.emptyStateDropzone {
  height: 100%;
  display: flex;
  align-items: center;
  align-content: center;
}

.fileName {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: white;
  margin-bottom: 10px;
}

.fileType {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #eaedf3;
}

.progressContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(44, 48, 65, 1);
  border-radius: 4.1px;
  padding-top: 30px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
}

.successIcon {
  padding-bottom: 10px;
}

.successIconApproved {
  padding-bottom: 10px;
}

.showPopUpUploader {
  background-color: #2c3041;
  border-radius: 4px;
  padding: 10px 20px;
  margin: 6%;
  vertical-align: middle;
  height: 90%;
  position: absolute;
  top: 0;
  left: 1%;
  width: 78%;
}

.emptyStateUploaderIconContainer {
  color: #9ea0a5;
  display: inline-block;
  line-height: 22px;
  margin: 10px;
}

.emptyStateUploaderIcon {
  display: flex;
}

.emptyStateUploaderPlaceholder {
  color: white;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 30px;
}

.uploadCaption {
  margin-top: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 22px;
  color: white;
  text-align: center;
  width: 100%;
  display: inline-block;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(44, 48, 65, 1);
  border-radius: 4.1px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.uploaderContainer {
  width: 100%;
}

.imageFieldInput {
  margin-top: 20px;
  margin-left: 22px;
  margin-right: 22px;
  padding-bottom: 20px;
  border-bottom: 1px solid #5b5f72;
}

.fileDetailsContainer {
  margin-bottom: 15px;
}

.dimensionInputContainer {
  width: 45%;
}

.imageDimensionsInputContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.deleteImageField {
  display: none;
}

.progressContainer:hover .deleteImageField {
  display: flex;
  cursor: pointer;
  padding-bottom: 10px;
}

.progressContainer:hover .successIcon {
  display: none;
}
