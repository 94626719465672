.pill {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 4px 12px 4px 12px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 38px;
  cursor: pointer;
}

.pillDisabled {
  color: rgba(138, 142, 150, 1);
  background: #2c3041;
  border-radius: 9.5px;
  padding: 4px 12px 4px 12px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 38px;
  cursor: pointer;
}

.pillActive {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 4px 12px 4px 12px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 38px;
  cursor: pointer;
  background-color: #1b8c96;
}

.pill:hover {
  border: 2px solid #6f7283;
  height: 11px;
  padding: 4px 10px 4px 10px;
}

.pillContainer {
  padding-left: 5px;
}
.forMe {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 4px 12px 4px 12px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 38px;
  cursor: pointer;
}
.forMeActive {
  background: #2c3041;
  border-radius: 9.5px;
  padding: 4px 12px 4px 12px;
  font-weight: 500;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.5px;
  position: relative;
  height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 38px;
  cursor: pointer;
  background-color: #1b8c96;
}

.forMe:hover {
  border: 2px solid #6f7283;
  height: 11px;
  padding: 4px 10px 4px 10px;
}

.downArrow {
  margin-left: 3px;
}

.referenceActionsDropDownContainer {
  color: #9ea0a5;
  cursor: pointer;
  font-size: 10px;
}

.commentorNameText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 150px !important;
  display: block;
}

@media screen and (min-width: 1440px) {
  .pillContainer {
    padding-left: 15px;
  }
}
