.approverListItem {
  border-bottom: 1px solid #eaedf3;

  border-left: solid 3px #fff;
  padding: 15px 20px 15px 30px;

  &:first-child {
    border-top: 1px solid #eaedf3;
  }
  &:last-child {
  }
  &.added {
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: 1px solid #eaedf3;
    }
  }
}

.approverListItemInner {
  display: flex;
  align-items: center;
}

.hoverContainer {
  height: 30px;
  width: 100%;
}

.approverListItem.selected,
.approverListItem:hover {
  border-left: solid 3px #1b8c96;
  background-color: #f6fdfd;

  &.approvedDocument {
    background: #fff;
    border-left: solid 3px #fff;
  }
}

.approvedDocument {
  &.approverListItem:hover {
    .approvalStatus {
      display: block !important;
    }
  }
}
.approverListItemAvatarContainer {
  margin-right: 25px;
}
.approverListItemAvatar {
  background-color: #496c8b;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  padding: 5px;
  text-align: center;
  width: 38px;
  height: 38px;

  .deactive & {
    border: 1px solid #bac0cb;
    background-color: #fff;
    color: #bac0cb;
  }
}

.approverListItemNameplate {
  width: 100%;
  //display: inline-block;
  min-width: 25%;

  .deactive & {
    max-width: 50%;
    min-width: 50%;
  }
}

.approverListItemInitials {
  font-size: 1.125rem; /* 50% of parent */
  line-height: 1;
  position: relative;
  top: calc(38px / 4); /* 25% of parent */
}

.approverListItemNameplate span {
  text-decoration: none !important;
}

.approverListItemNameplateName {
  color: #3e3f42;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  .deactive & {
    color: #bac0cb;
  }
}

.approverListItemNameplateTitle {
  display: flex;
  flex-direction: column;
  color: #6b6c6f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  .deactive & {
    color: #bac0cb;
  }
}

.actionsInner {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: -3px;

  &.isObserver {
    margin-top: 1px;
  }
}

.actionSelect {
  width: 240px;
  margin-right: 1.5rem;
}

.actionDelete {
  margin-left: 20px;
  .isObserver & {
    margin-top: 1px;
  }
}

.actionObserverToggleOn {
  display: block;

  float: right;
  margin: auto 0 auto auto;
}

.addItemObserverToggle {
}

.actionObserverToggle {
  display: none;
  margin-top: 3px;
  margin-left: 30px;
}

.approverListItem:hover .actionObserverToggle {
  display: block;
}

.actionObserverLabel {
  float: right;
  margin-top: 5px;
}

.removeApprover {
  transform: rotate(45deg);
}

.approvalStatus {
  span {
    white-space: nowrap;
  }
}

.approverListItem {
  &:hover {
    .approvalStatus {
      display: none;
    }
    .resetApprovalBadgePassive,
    .resetApprovalBadgeActive {
      display: flex;
      align-items: center;
    }
    .resetApprovalIcon {
      display: block;
    }
    .observerResetBadge,
    .waitingResetBadge {
      display: none;
    }
    .approvalBadge {
      display: block;
    }
  }
  &.readOnly {
    &:hover {
      .approvalStatus {
        display: block;
      }
    }
  }

  .approvalBadge {
    cursor: default;
  }
}

.approvedBadge {
  background-color: #15cd93;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.rejectedBadge {
  background-color: #d94c53;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.waitingBadge {
  background-color: #ff9c79;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.observerBadge {
  background-color: #9ea0a5;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;

  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.deleteApprover {
  cursor: pointer;
  float: right;
  font-size: 28px;
  line-height: 28px;
  margin-top: -9px;
  padding-top: 5px;
}

.actionsContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.resetApprovalBadgePassive,
.resetApprovalBadgeActive {
  line-height: 1.5;
  border-radius: 4px;
  color: #fff;
  padding: 3px 8px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  img {
    margin-left: 7px;
  }
}

.observerResetBadge {
  background-color: #9ea0a5;
}

.approvedResetBadge {
  background-color: #15cd93;
}

.waitingResetBadge {
  background-color: #ff9c79;
}

.rejectedResetBadge {
  background-color: #d94c53;
}

.resetApprovalBadgeActive {
  cursor: pointer;
}

.resetApprovalIcon {
  display: none;
}

.copyLink {
  display: inline-block;
  cursor: pointer;

  .text {
    color: #00afbf;
    font-weight: 500;
  }
}
